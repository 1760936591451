.visually-hidden{
  display: none;
}
.carousel-control{
  background-color: $danger-color;
  border-radius: 50%;
  opacity: 1;
  text-shadow: none;

  &:hover,
  &:focus{
      opacity: 1;
      background-color: $danger-states-color;
  }
}

.carousel-control-prev{
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: transparent;
  outline: none;
  border: none;
}
.carousel-control-next{
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: transparent;
  outline: none;
  border: none;
}
.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .fa, .carousel-control .fa {
  display: inline-block;
  z-index: 5;
}
.carousel-control .fa{
  font-size: 26px;
  margin: 2px;
}
.carousel-control.left, .carousel-control.right {
  background-image: none;
}

.page-carousel{
  border-radius: $border-radius-extreme !important;
  border: none !important;
  width: 100%;
}

.carousel-item img {
  width: 100%;
}
.carousel-item{
    transition: none;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  margin-bottom: $carousel-control-margin-bottom;
  list-style: none;

  button {
    position: relative;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba($carousel-indicator-active-bg, .5);
    border: 0;
    outline: 0;
    // Use pseudo classes to increase the hit area by 10px on top and bottom.
    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: $carousel-indicator-active-bg;
  }
}
