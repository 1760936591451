/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap.scss";

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(90, 90, 90); 
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(90, 90, 90); 
    border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #e52449; 
}

.card .card-image img {
    max-height: 173.56px;
    min-height: 173.56px;
}
.navbar a {
    cursor: pointer;
}

.group-title{
    text-align: center;
    color:#308ed5;
    text-transform: capitalize;
    text-decoration:none !important;
    cursor:pointer;
    font-size: 2em;
    font-weight: 300 !important;
}

.info p {
    font-size: 1.2em !important;
    color:#828282 !important;
}

.info .info-title{
    font-size: 1.5em;
}

.info-horizontal ul {
    font-size: 1.1em !important;
}

@media (min-width: 576px){
    .col-sm-3 {
        flex: 0 0 22.5%;
        max-width: 22.5%;
    }
}

.group-parent{
    display:block;
    margin-top:30px;
}

.card-description {
    min-height: 83px;
}

.card-title{
    min-height: 36px;
}
.category{
    font-size: 0.8em !important;
}
.darkbox-text{
    color: #eee !important;
    font-size: 1.2em !important;
    margin:15px;
}
.features-6 .info:first-child {
    padding-top: 35px !important;
}


.page-header .page-header-image {
    z-index: 0 !important;
}


.wrapper {
    background-color: #eee;
}

.wrapper div.features-4 {
    padding-top: 62px;
}

.wrapper div.features-4 .card {
    margin-top:50px;
}

.wrapper div.features-4 h2.title {
    padding-top:60px !important;
    text-align: center;

}

.wrapper .input-group {
    margin-top:25px;
}

input::placeholder {
    color:rgb(132, 132, 132) !important;
} 