// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.nav-tabs {
    border: 0;
    padding: $padding-large-vertical $padding-base-horizontal;

    &:not(.nav-tabs-neutral) > .nav-item > .nav-link.active{
        box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
    }

    .card &{
        border-top-right-radius: $border-radius-small;
        border-top-left-radius: $border-radius-small;
    }

    > .nav-item{
        > .nav-link{
            color: $default-color;
            margin: 0;
            margin-right: 5px;
            background-color: $transparent-bg;
            border: 1px solid $transparent-bg;
            border-radius: 30px;
            font-size: $font-size-base;
            padding: $padding-btn-vertical $padding-round-horizontal;
            line-height: $line-height-general;

            &:hover {
                background-color: $transparent-bg;
            }

            &.active{
                background-color: $light-black;
                border-radius: 30px;
                color: $white-color;
            }

            i.now-ui-icons{
                font-size: 14px;
                position: relative;
                top: 1px;
                margin-right: 3px;
            }
        }

        &.disabled > .nav-link,
        &.disabled > .nav-link:hover {
            color: rgba(255,255,255,0.5);
        }
    }
}

.nav-tabs-neutral{
  .nav-tabs{
    .nav-item{
       > .nav-link{
           color: $white-color;

           &.active{
               background-color: $opacity-2;
               color: $white-color;
           }
       }
   }
  }
}

.nav-tabs-primary{
  .nav-tabs{
    > .nav-item{
        > .nav-link{
            &.active{
                background-color: $primary-color;
            }
        }
    }
  }
}

.nav-tabs-info{
  .nav-tabs{
    > .nav-item{
        > .nav-link{
            &.active{
                background-color: $info-color;
            }
        }
    }
  }
}

.nav-tabs-danger{
  .nav-tabs{
    > .nav-item{
        > .nav-link{
            &.active{
                background-color: $danger-color;
            }
        }
    }
  }
}

.nav-tabs-warning{
  .nav-tabs{
    > .nav-item{
        > .nav-link{
            &.active{
                background-color: $warning-color;
            }
        }
    }
  }
}

.nav-tabs-success{
  .nav-tabs{
    > .nav-item{
        > .nav-link{
            &.active{
                background-color: $success-color;
            }
        }
    }
  }
}
[data-tabs-color="orange"]{
  .nav-tabs{
    background-color: $primary-color;

    .nav-item .nav-link{
        color: $white-color;
        &.active{
          box-shadow: none;
          background-color: $opacity-2;
        }
    }
  }
}
.accordion-item {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  .accordion-header {
      padding: 0.75rem 0;
      position: relative;
      border-bottom: 0;
      background-color: transparent;
      border-radius: 0;
      margin-bottom: 0;
      border-bottom: 1px solid #e3e3e3;
      ;

      .accordion-button {
          margin: 0 0 10px;
          background: inherit !important;
          color: #444 !important;
          display: block;
          border-bottom: none !important;
          width: 100%;
          padding: 0;
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          border: 0;
          border-radius: 0.1875rem;
          text-transform: none;
          outline: none;

          a {
              text-decoration: none;
              border-bottom: 2px solid transparent;
              color: #444;
              background-color: transparent;

              i {
                  float: right;
                  color: #f96332;
              }
          }
      }

      .accordion-button[aria-expanded="true"] {
          a {
              i {
                  transform: rotate(180deg);
              }
          }
      }

  }
}

.accordion-body {
  flex: 1 1 auto;
  padding: 0.75rem;
}